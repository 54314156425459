import DataJson from '../../data.json';

export function getCdnUrl(s3Url) {
  if (
    !DataJson.cdnHost ||
    s3Url.indexOf(`https://${DataJson.clientId}-`) !== 0
  ) {
    return s3Url;
  }

  const [https, _, bucket, ...parts] = s3Url.split('/');
  return [`https://${DataJson.cdnHost}`, ...parts].join('/');
}
